import React from 'react'
import { Link, navigate } from 'gatsby'
import '../styles/note.css'
import kebabCase from 'lodash/kebabCase'

export default function NoteList({ notes }) {
  return (
    <div className="block note-cards note-list">
      {notes.map((data, index) => (
        <div
          className="note-area box-feed"
          key={index}
          role="button"
          tabIndex={0}
          onClick={() => {
            navigate(data.node.fields.slug)
          }}
          onKeyDown={event => {
            if (event.key === 'Enter' || event.key === ' ') {
              event.preventDefault()
              navigate(data.node.fields.slug)
            }
          }}
        >
          <h4 className="note-title">
            <Link to={`${data.node.fields.slug}`}>
              {data.node.fields.title}
            </Link>
          </h4>
          <p className="note-excerpt">
            {data.node.fields.excerpt
              ? data.node.fields.excerpt
              : data.node.excerpt}
          </p>

          {data.node.frontmatter.tags?.length ? (
            <p className="note-tag-list" onClick={e => e.stopPropagation()}>
              Tagged with:{' '}
              {data.node.frontmatter && data.node.frontmatter.tags
                ? data.node.frontmatter.tags.map((tag, index) => (
                    <span key={index}>
                      <Link to={`/tags/${kebabCase(tag)}`}>#{tag}</Link>
                      {index < data.node.frontmatter.tags.length - 1
                        ? ', '
                        : ''}
                    </span>
                  ))
                : 'No Tags'}
            </p>
          ) : null}
        </div>
      ))}
    </div>
  )
}
